import "./forgotPasswordForms.scss";
import "../../styles/formStyles/formStyles.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ENDPOINT_ACCOUNT } from "../../endpoints/url";
import requestHeader from "../../Header";

const ForgotPasswordForms = ({ showEmailForm, setEmailForm }) => {
  const [email, setEmail] = useState("");
  const [moveForward, setMoveForward] = useState(false);
  const [isFieldFocused, setFieldFocused] = useState(false);

  const handleEmailFieldClick = () => {
    setFieldFocused(!isFieldFocused);
  };

  const handleEmailContainerClick = () => {
    setFieldFocused(true);
  };

  const sendPasswordResetLink = async (emailId) => {
    try {
      const response = await fetch(
        `${ENDPOINT_ACCOUNT}/sendResetPasswordLink`,
        {
          method: "POST",
          headers: requestHeader,
          credentials: "include",
          body: JSON.stringify({
            email: emailId,
          }),
        }
      );
      if (response.ok) {
        const res = await response.json();
        setMoveForward(true);
        setEmailForm(false);
        console.log(res);
      } else {
        const errorData = await response.json();
        console.log(errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  return (
    <>
      <div className="fp-wrapper__title">
        <h2
          style={{
            margin: 0,
          }}
        >
          Forgot Password?
        </h2>
      </div>
      <div className="fp-wrapper__description">
        <p
          style={{
            margin: 0,
          }}
        >
          Enter the email you previously used to create your Matchmaker account,
          and we will send you your reset instructions.
        </p>
      </div>
      <div
        className="fp-wrapper__inputfield"
        onClick={handleEmailContainerClick}
      >
        {isFieldFocused ? (
          <>
            <div className="pass-input">
              <label className="input-label">Email</label>
              <input
                className="form-input"
                type="email"
                autoComplete={false}
                name="email"
                onBlur={handleEmailFieldClick}
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <span className="input-placeholder">Email Address</span>
          </>
        )}
      </div>
      <div className="fp-wrapper__sendemailbtn">
        <button
          className="sendemailbtn-text"
          onClick={() => {
            if (email === "") return;
            sendPasswordResetLink(email);
          }}
        >
          Send Reset Instructions
        </button>
      </div>
      <div className="link-to-login-container">
        <Link to="/signin" className="login__text--bold">
          Back to Sign In Page
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordForms;
