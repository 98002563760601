import { ENDPOINT_ACCOUNT } from "../endpoints/url";
import requestHeader from "../Header";
export const updateAccountInfo = async (infoToBeUpdated) => {
try {
    console.log(infoToBeUpdated,"infoToBeUpdated")
    const response = await fetch(`${ENDPOINT_ACCOUNT}/update-account-info`, {
      method: "POST",
      headers: requestHeader,
      credentials: "include",
      body: JSON.stringify(infoToBeUpdated),
    });
    if (response.ok) {
      const res = await response.json();
      console.log(res);
    } else {
      const errorData = await response.json();
      console.log(errorData.error);
    }
  } catch (error) {
    console.log("Error:", error.message);
}
}