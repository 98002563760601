import "./TopRecommendations.scss";
import { useAuthContext } from "../../contexts/authContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarCard from "../carCard/carCard";

function TopRecommendations() {
  const { matchmaker } = useAuthContext();

  const slicedCarsMaeTrimInfo = matchmaker?.profileQuizMaeTrims.slice(0, 6);

  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: true,
  };

  return (
    <div className="recommendations">
      <h2 className="recommendations__title">
        Our Top Recommendations For You
      </h2>
      <div className="recommendations__slider">
        <Slider {...settings}>
          {slicedCarsMaeTrimInfo.map((vehicle) => (
             <CarCard
             key = {vehicle._maeTrimId}
             carInfo={vehicle} 
             cardSelectionOnClick={()=>{}}
             isCardSelected={false} 
             isCardSelectable={false}  
             isCardLinkingToMDP={true} 
             showLikeDislike={false}
             isCompatibilityVisible={true}
             />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default TopRecommendations;
