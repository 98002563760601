import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/loadingScreen/loadingScreen";

const GoogleNavigation = () => {
  const { matchmaker, isAuthContextLoading, isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isAuthContextLoading) {
      if (!isLoggedIn) {
        navigate("/");
      }
      else if (
          matchmaker?.profileQuiz?.length === 0 &&
          matchmaker?.profileQuizMaeTrims?.length === 0
        ) {
          console.log("Redirect to matchmaker quiz.");
          navigate("/matchmaker-quiz");
        } 
      else {
        navigate("/compare")
      }
    }  
  }, [isAuthContextLoading]);

  if(isAuthContextLoading) return <LoadingScreen/>

  return (
    <div>
      Something went wrong...
    </div>
  );
};

export default GoogleNavigation;
