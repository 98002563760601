import './labelTooltip.scss';
import {ReactComponent as Info} from '../../assets/icons/info.svg';

export default function LabelWithTooltip({ tooltipClassId, label, labelStyleClass, tooltipInfo }) {
    function showTooltip(tooltipClass) {
        const tooltip = document.querySelector(tooltipClass);
        tooltip.classList.add("showTooltip");
    }
    
    function hideTooltip(tooltipClass) {
        const tooltip = document.querySelector(tooltipClass);
        tooltip.classList.remove("showTooltip");
        
    }
  return (
    <div className="label-with-info">
      <p
        className={`${labelStyleClass ? labelStyleClass : "tooltip-label-text"}`}
        style={{ display: "block" }}
        onMouseEnter={() => showTooltip(`.${tooltipClassId}`)}
        onMouseLeave={() => hideTooltip(`.${tooltipClassId}`)}
      >
        {`${label} `}
        <Info />
      </p>
      <div className={`tooltip ${tooltipClassId}`}>{tooltipInfo}</div>
    </div>
  );
  }