import { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/images/X.svg";
import ButtonGradient from "../buttonGradient/ButtonGradient.jsx";
import { useAuthContext } from "../../contexts/authContext.jsx";
import { trackAndUpdateComparisonChartInfo } from "../../services/comparisonchart.js";

const FeatureBlock = ({ feature, userAddedFeatures, setUserAddedFeatures }) => {
  /*const iconSrcBlur = optionInfo.greyicon
    ? require(`../../assets/icons/${optionInfo.greyicon}`)
    : "";*/

  const { matchmaker } = useAuthContext();
  const [included, setIncluded] = useState(userAddedFeatures.includes(feature));

  function formatCapitalization(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  function onHandleSelect() {
    if (included) {
      userAddedFeatures = userAddedFeatures.filter((item) => {
        return item !== feature;
      });
    } else {
      userAddedFeatures.push(feature);
    }
    setUserAddedFeatures(userAddedFeatures);
    setIncluded(!included);
  }

  return (
    <>
      {feature !== "_id" &&
      !matchmaker.profileQuizMaeTrims[0].totalFeatures.includes(feature) ? (
        <div
          className={`feature-block ${included ? "f-selected" : ""}`}
          onClick={onHandleSelect}
        >
          {/*{iconSrcBlur ? (
          <span className="feature-block__greyscalecontainer">
            <img
              className="feature-block__greyscalecontainer--greyscaleimg"
              src={iconSrcBlur}
            />
          </span>
        ) : null}*/}

          <div className="feature-block__container">
            <div className="feature-block__textbox">
              <span className="feature-block__text">
                {formatCapitalization(feature)}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function FeaturePopup({
  toggleHelpPopup,
  setPopupState,
  userAddedFeatures,
  setUserAddedFeatures,
}) {
  const { matchmaker, lastChartId } = useAuthContext();
  const [extraFeatureList, setExtraFeatureList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      matchmaker.profileQuizMaeTrims &&
      matchmaker.profileQuizMaeTrims.length > 0
    ) {
      setExtraFeatureList(
        Object.keys(matchmaker.profileQuizMaeTrims[0].vehicleInfo.maeFeatures)
      );
    }
  }, [matchmaker]);

  return (
    <div
      className="customization_popup_container"
      style={{ width: "50vw", height: "75vh", overflowY: "auto" }}
    >
      <div
        className="close_popup_container"
        style={{ marginBlock: "0.5rem 0.25rem" }}
        onClick={() => {
          toggleHelpPopup(false);
        }}
      >
        <Close />
      </div>
      <div className="popup_content">
        <div className="popup_header">
          <p className="popup_header--maintext">Add More Features</p>
        </div>
        <div className="other_features_container">
          {extraFeatureList.map((feature, index) => {
            return (
              <FeatureBlock
                feature={feature}
                userAddedFeatures={userAddedFeatures}
                setUserAddedFeatures={setUserAddedFeatures}
                key={index}
              />
            );
          })}
        </div>
        <div className="button_container">
          <ButtonGradient
            text="Submit"
            onClick={() => {
              trackAndUpdateComparisonChartInfo({
                chartId: lastChartId,
                interactionType: "changed features in compare chart",
                additionalDetails: {
                  changedFeatures: userAddedFeatures,
                },
              });
              toggleHelpPopup(false);
              setPopupState({ show: true, textId: "addedFeatureText" });
            }}
          />
        </div>
      </div>
    </div>
  );
}
