import "./App.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuthContext } from "./contexts/authContext";
import Main from "./pages/Main/Main";
import NotFound from "./pages/NotFound/NotFound";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Quiz from "./pages/Quiz/Quiz";
import SpeedDatingQuiz from "./pages/SpeedDatingQuiz/SpeedDatingQuiz";
import SpeedDating from "./pages/SpeedDating/SpeedDating";
import MyProfile from "./pages/MyProfile/Profile";
import Gallery from "./pages/Gallery/Gallery";
import SpeedDatingLoading from "./pages/SpeedDatingLoading/SpeedDatingLoading";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MDP from "./pages/MDP/MDP";
import LoadingScreen from "./components/loadingScreen/loadingScreen";
import AddCarsForComparison from "./components/addCarsForComparison/addCarsForComparison";
import ProtectedRoute from "./utils/protectedRoutes";
import GoogleNavigation from "./pages/GoogleNavigation/GoogleNavigation";
import ComparisonChartPage from "./pages/ComparisonChart/ComparisonChart";

function App() {
  const {
    isLoggedIn,
    matchmaker,
    lastAction,
    user,
    isAuthContextLoading,
    printMatchmaker,
    userSource
  } = useAuthContext();
  
  useEffect(() => {
    console.log(
      "Use effect, App Check",
      isAuthContextLoading,
      isLoggedIn,
      matchmaker,
      user,
      lastAction,
      userSource
    );
    if (!isAuthContextLoading) {
      printMatchmaker();
    }
  }, [matchmaker, user, isAuthContextLoading]);

  if (isAuthContextLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/speed-dating/quiz" element={<SpeedDatingQuiz />} />
          <Route path="/matches" element={<Gallery />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/maetrims/:maeTrimId" element={<MDP />} />
          <Route path="/compare" element={<ComparisonChartPage />} />
          <Route path="/add-cars" element={<AddCarsForComparison />} />
        </Route>
        <Route path="/" element={<Main />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/google-navigation-upon-logging-in" element={<GoogleNavigation/>} />
        <Route path="/matchmaker-quiz" element={<Quiz />} />
        <Route path="/speed-dating" element={<SpeedDating />} />
        <Route path="/loading" element={<SpeedDatingLoading />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword show="email" />}
        />
        <Route
          path="/password-reset/:userId/:token"
          element={<ForgotPassword show="reset" />}
        />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
