import "./Sidebar.scss";
// import yellowLogo from "../../assets/logos/Mae-Core-Logo-Yellow.png";
import sidebarLogo from "../../assets/logos/sidebar-mae-logo.svg";
import SidebarAccountInfo from "../sidebarAccountInfo/SidebarAccountInfo";
import SidebarMenu from "../sidebarMenu/SidebarMenu";
// import SidebarFooter from "../sidebarFooter/SidebarFooter";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import HamburgerMenuItem from "../HamburgerMenuItem/HamburgerMenuItem";
import { useAuthContext } from "../../contexts/authContext";
import { trackUserEventService } from "../../services/userEvent";
import { BUTTON_CLICK_EVENT_TYPE } from "../../constants/userEvents";
import UpdatePopup from "../updatePopup/updatePopup";
import { updateComparisonChartInfo } from "../../services/comparisonchart";
import { DEALERSHIP_CONFIG } from "../../config/dealershipConfig.js";

function Sidebar() {
  const { matchmaker, setLastChartId, userSource } =
    useAuthContext();
  const [showOverlayMenu, setShowOverlayMenu] = useState(false);
  const [showCompareChartWarning, setShowCompareChartWarning] = useState(false);
  const location = useLocation();

  const DEALERSHIP_SOURCE = userSource in DEALERSHIP_CONFIG;
  const DEALERSHIP_LOGO = DEALERSHIP_CONFIG[userSource]?.logo
  const DEALERSHIP_NAME = DEALERSHIP_CONFIG[userSource]?.name

  const compareChartWarningText = "This feature is enabled after speed dating!";

  const toggleOverlayMenu = () => {
    console.log("open");
    setShowOverlayMenu((prevValue) => !prevValue);
  };

  async function goToComparePage() {
    if (matchmaker?.profileQuizMaeTrims?.length === 0) {
      setShowCompareChartWarning(true);
    }
    trackUserEventService(
      BUTTON_CLICK_EVENT_TYPE,
      "Compare Button Clicked in Hamburger Menu"
    );
  }

  return (
    <>
      {showCompareChartWarning && (
        <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
          <UpdatePopup
            Popupshow={setShowCompareChartWarning}
            main={compareChartWarningText}
          />
        </div>
      )}
      <div className="sidebar">
        <div className="sidebar__wrapper">
        {DEALERSHIP_SOURCE ?
              <img
              className="sidebar__dealership-logo"
              src={DEALERSHIP_LOGO}
              alt={DEALERSHIP_NAME}
            /> : <div className="sidebar__empty_padding"></div>}
          <div className="sidebar__logo">
            <Link to="/">
              <img
                className="sidebar__logo--img"
                src={sidebarLogo}
                alt=""
              ></img>
            </Link>
          </div>
          <SidebarMenu />
        </div>
        <div className="sidebar__accountinfo">
          <SidebarAccountInfo />
        </div>
        <div className="sidebar__hamburger-menu" onClick={toggleOverlayMenu}>
          <div className="sidebar__hamburger-menu--line"></div>
          <div className="sidebar__hamburger-menu--line"></div>
          <div className="sidebar__hamburger-menu--line"></div>
        </div>
        <div
          className={
            showOverlayMenu ? "sidebar__overlay--open" : "sidebar__overlay"
          }
          onClick={toggleOverlayMenu}
        >
          <div className="sidebar__overlay--crossicon">
            <div>&#10005;</div>
          </div>
          <div className="sidebar__overlay--menulinks">
            <HamburgerMenuItem
              icon={false}
              label="Speed Dating"
              to="/speed-dating"
            />

            <HamburgerMenuItem
              label="Matches"
              to={`/matches`} // vehicleId should be passed to this component
            />

            <HamburgerMenuItem
              label="Compare"
              sendTo={
                matchmaker?.profileQuizMaeTrims?.length > 0 ? "/compare" : ""
              }
              to={matchmaker?.profileQuizMaeTrims?.length > 0 ? "/compare" : ""}
              onClick={() => goToComparePage()}
            />
            <HamburgerMenuItem
              label="Resources"
              to="https://www.makingautoeasy.com/freebies"
              isSelected={location.pathname === "/resources"}
            />

            <HamburgerMenuItem
              label="Profile"
              to={`/my-profile`} // vehicleId should be passed to this component
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
