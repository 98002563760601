import './helpPopup.scss';
import { updateAccountInfo } from "../../services/account.js";
import { ReactComponent as MaeBadgeLogoBright } from "../../assets/logos/mae-badge-logo-bright.svg";
import { ReactComponent as Close } from "../../assets/images/X.svg";
import helpVideo from "../../assets/videos/helpPopUp.mp4";
import { useAuthContext } from '../../contexts/authContext';

export default function HelpPopup({toggleHelpPopup}) {

    const {comparisonChartViewed, setComparisonChartViewed} = useAuthContext();
    function closePopup() {
        toggleHelpPopup(false);
        async function callToUpdateAccountInfo() {
            await updateAccountInfo({comparisonChartViewed : true});
            setComparisonChartViewed(true);
        }
        if(!comparisonChartViewed) callToUpdateAccountInfo();
      }

      return (
        <div className="blur-overlay">
            <div className="help_popup_container">
                <div>
                    <div className="badge_logo_bright">
                        <MaeBadgeLogoBright />
                    </div>
                <div className="help_close_popup_container" onClick={closePopup}>
                    <Close />
                </div>
                <p className="help_popup_container__heading">How to use the Comparison Chart </p>
                <p className="help_popup_container__subheading"> From the founder of Mae, Vivian</p>
                <div className="video-container">
                    <video controls autoPlay loop muted>
                    <source src={helpVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
                </div>
            </div>
        </div>
      )
};