/* eslint-disable react-hooks/exhaustive-deps */
import './ComparisonChart.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"; 
import { useAuthContext } from '../../contexts/authContext';
import { DEALERSHIP_CONFIG } from "../../config/dealershipConfig.js";
import {
    BUTTON_CLICK_EVENT_TYPE,
} from "../../constants/userEvents.js";
import { trackAndUpdateComparisonChartInfo } from '../../services/comparisonchart.js';

import CustomizationPopup from '../../components/customizationPopup/customizationPopup';
import FeaturePopup from '../../components/featurePopup/featurePopup';
import PopupDisplay from '../../components/popupDisplay/popupDisplay';
import HelpPopup from '../../components/helpPopup/helpPopup';
import Banner from '../../components/banner/banner.jsx';
import ComparisonChart from '../../components/comparisonChart/comparisonChart.jsx';

import dashboardLogo from "../../assets/logos/Mae-Core-Logo-Yellow.png";
import { ReactComponent as BackIcon } from "../../assets/images/back-arrow.svg";
import { ReactComponent as InfoYellow } from "../../assets/icons/info-yellow.svg";




export default function ComparisonChartPage() {

    const { comparisonChartViewed, userSource, userAddedFeatures, setUserAddedFeatures, matchmaker, isAuthContextLoading, lastChartId} = useAuthContext();
    const navigate = useNavigate();
    const [popupState, setPopupState] = useState({ show: false });
    const [exclusiveCtaPopup, setExclusiveCtaPopup] = useState(false);
    const [FeatureCtaPopup, setFeatureCtaPopup] = useState(false);
    const [isHelpPopupVisible, setIsHelpPopupVisible] = useState(
        !comparisonChartViewed
    );
    const [swipeOn, setSwipeOn] = useState(false);

    const DEALERSHIP_NAME = DEALERSHIP_CONFIG[userSource]?.name;
    const DEALERSHIP_OFFER_TEXT = DEALERSHIP_CONFIG[userSource]?.offerText;
    const DEALERSHIP_OFFER_BUTTON_TEXT = DEALERSHIP_CONFIG[userSource]?.offerButtonText;
    const DEALERSHIP_LOGO = DEALERSHIP_CONFIG[userSource]?.logo;
    const DEALERSHIP_SOURCE = userSource in DEALERSHIP_CONFIG;

    useEffect(() => {
        if (window.innerWidth <= 1024) {
          setPopupState({ show: true, textId: "mobileViewNotReady" });
        }
    }, []);

    useEffect(() => {
        if (exclusiveCtaPopup || FeatureCtaPopup || isHelpPopupVisible) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
    }, [exclusiveCtaPopup, FeatureCtaPopup, isHelpPopupVisible]);

    useEffect(() => {
        if (isAuthContextLoading) {
          return;
        }
        if (
          userAddedFeatures.length > 0 ||
          matchmaker.profileQuizMaeTrims.length <= 0
        )
          return;
        Object.keys(
          matchmaker.profileQuizMaeTrims[0].vehicleInfo.maeFeatures
        ).forEach((feature) => {
          if (
            userAddedFeatures.length < 5 &&
            !matchmaker?.profileQuizMaeTrims[0].totalFeatures.includes(feature) &&
            feature !== "_id"
          ) {
            userAddedFeatures.push(feature);
          }
        });
        setUserAddedFeatures(userAddedFeatures);
      }, [userAddedFeatures]);

    const toggleHelpPopup = (isVisible) => {
        setIsHelpPopupVisible(isVisible);
    };

    const  exclusiveCtaClicked = () =>  {
        window.scrollTo(0, 0);
        setExclusiveCtaPopup(true);
        trackBannerButton();
      }
    
    const featureCtaClicked = () => {
        window.scrollTo(0, 0);
        setFeatureCtaPopup(true);
    }

    const onBackButton = () => {
        navigate("/matches");
    };

    function trackBannerButton() {
        trackAndUpdateComparisonChartInfo({
          chartId: lastChartId,
          interactionType: BUTTON_CLICK_EVENT_TYPE,
          additionalDetails: {
            buttonType: DEALERSHIP_SOURCE ? "CLAIM_OFFER" : "BUY_WITH_MAE",
            dealershipName: DEALERSHIP_NAME,
          },
        });
    }

    return (<> 
       {exclusiveCtaPopup ? (
        <CustomizationPopup
          togglePopup={setExclusiveCtaPopup}
          setPopupState={setPopupState}
          dealershipName={DEALERSHIP_NAME}
          offerText={DEALERSHIP_OFFER_TEXT}
        />
      ) : null}
      {FeatureCtaPopup ? (
        <FeaturePopup
          toggleHelpPopup={setFeatureCtaPopup}
          setPopupState={setPopupState}
          userAddedFeatures={userAddedFeatures}
          setUserAddedFeatures={setUserAddedFeatures}
        />
      ) : null}

      <PopupDisplay popupUpdate={popupState} setPopupUpdate={setPopupState} />

      {isHelpPopupVisible && <HelpPopup toggleHelpPopup={toggleHelpPopup} />}

      <div
      className={`comparechart ${
          exclusiveCtaPopup || FeatureCtaPopup ? "blurred" : ""
        }`}
    >
      {DEALERSHIP_SOURCE ? (
        <div className="comparechart__title-container">
          <img
            className="dealership-logo"
            src={DEALERSHIP_LOGO}
            alt={DEALERSHIP_NAME}
          />

          <div className="back-button-container" onClick={onBackButton}>
            <BackIcon />
            <p className="back-button-container__text">Back</p>
          </div>
        </div>
      ) : null}

      {DEALERSHIP_SOURCE ? (
        <div
          className="comparechart__title-container-with-powered"
        >
          <h3 className="comparison-chart-title">Comparison Chart</h3>
          <div
            className="comparechart__poweredBy"
          >
            <div
              className="comparechart__poweredBy--text"
            >
              powered by
            </div>
            <img
              alt=""
              className="comparechart__poweredBy--logo"
              src={dashboardLogo}
            />
          </div>
        </div>
      ) : (
        <div className="comparechart__title-container">
          <h3 className="comparison-chart-title">Comparison Chart</h3>

          <div className="back-button-container" onClick={onBackButton}>
            <BackIcon />
            <p className="back-button-container__text">Back</p>
          </div>
        </div>
      )}

      <Banner
        onClick={exclusiveCtaClicked}
        text={
          DEALERSHIP_SOURCE
            ? DEALERSHIP_OFFER_TEXT
            : "Get Exclusive deals and offers on these vehicles from Mae's hand-picked and trusted dealers near you."
        }
        buttonText={
          DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_BUTTON_TEXT : "Buy with mae"
        }
        heading={false}
      />
      <div className="help-link-container">
        <p
          className="help-link"
          onClick={(e) => {
            e.preventDefault();
            toggleHelpPopup(true);
          }}
        >
          <InfoYellow style={{ height: "1rem", marginRight: "0.5rem" }} /> How
          to use the comparison chart
        </p>
        {swipeOn ? <div class="scroll-hint">
          <p>Swipe →</p>
        </div> : null }

      </div>

      <ComparisonChart featuresSelected ={userAddedFeatures} featureCtaClicked={featureCtaClicked} setSwipeOn={setSwipeOn}/>

      <Banner
        onClick={exclusiveCtaClicked}
        text={
          DEALERSHIP_SOURCE
            ? DEALERSHIP_OFFER_TEXT
            : "Get Exclusive deals and offers on these vehicles from Mae's hand-picked and trusted dealers near you."
        }
        buttonText={
          DEALERSHIP_SOURCE ? DEALERSHIP_OFFER_BUTTON_TEXT : "Buy with mae"
        }
        heading={false}
      />
    </div>

    </>);
}