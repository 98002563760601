import "./addCarsForComparison.scss";
import { ReactComponent as X } from "../../assets/images/X.svg";
import MatchesList from "../matchesList/MatchesList";

function AddCarsForComparison() {

  return (
    <>
      {/* <div
        className="close_container"
        onClick={() => {
          navigate("/compare");
        }}
      >
        <X />
      </div> */}
      <div className="add-cars">
        <div className="add-cars__hero">
          <h1 className="add-cars__hero--title">Add Cars</h1>
        </div>
        <MatchesList selectionEnabled={true}/>
      </div>
    </>
  );
}

export default AddCarsForComparison;
