import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateProfileService } from "../services/profile";
import { updateAnonymousUserService } from "../services/userEvent";
import {
  MATCHMAKER_UPDATED,
  PROFILE_GENERATED,
  NOT_AUTHENTICATED,
  NO_LAST_ACTION,
} from "../constants/lastActions";
import { ENDPOINT_AUTHENTICATE } from "../endpoints/url";
import requestHeader from "../Header";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //curr user information
  const [user, setUser] = useState("Anonymous");
  //matchmaker object - { profileQuiz, profileQuizMaeTrims  }
  const [matchmaker, setMatchmaker] = useState({});
  //Last action of user - used to update matchmaker for several pages
  const [lastAction, setLastAction] = useState(NO_LAST_ACTION);
  //loading state to prepare all the user information
  const [isAuthContextLoading, setIsAuthContextLoading] = useState(true);
  const [triggerUpdateAuthContext, setTriggerUpdateAuthContext] = useState(1);
  //comparison chart state information for tracking and updating
  const [currentComparisonChartState, setCurrentComparisonChartState] =
    useState([]);
  // last comparison chart id
  const [lastChartId, setLastChartId] = useState("");
  // state for extra vehicles added by users on comparison chart
  const [extraVehicles, setExtraVehicles] = useState([]);
  // set of added features by users on comparison chart
  const [userAddedFeatures, setUserAddedFeatures] = useState([]);
  // user source
  const [userSource, setUserSource] = useState(
    localStorage.getItem("user-source")
  );
  // user source id
  const [userSourceId, setUserSourceId] = useState(
    localStorage.getItem("user-source-id")
  );
  // state to check it one state of comparison chart has been created
  const [comparisonChartViewed, setComparisonChartViewed] = useState(false);

  const location = useLocation();

  const printMatchmaker = () => {
    console.log("Matchmaker inside auth context", matchmaker);
  };

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsAuthContextLoading(true);
    console.log("Logout in auth context");
    localStorage.removeItem("anonymous-profile");
    localStorage.removeItem("user-source");
    localStorage.removeItem("user-source-id");
    setIsLoggedIn(false);
    setUser("Anonymous");
    setMatchmaker({});
    setIsAuthContextLoading(false);
    setLastAction(NO_LAST_ACTION);
    setTriggerUpdateAuthContext((prevCount) => prevCount + 1);
    setCurrentComparisonChartState({});
  };

  const updateAuthContext = async () => {
    setIsAuthContextLoading(true);
    try {
      const response = await fetch(`${ENDPOINT_AUTHENTICATE}/authenticate`, {
        method: "POST",
        headers: requestHeader,
        body: JSON.stringify({
          anonymousId: localStorage.getItem("anonymous-id"),
        }),
        credentials: "include",
      });

      const auth_response = await response.json();
      console.log(auth_response);

      if (auth_response.anonymousId !== undefined) {
        localStorage.setItem("anonymous-id", auth_response.anonymousId);
      }

      if (response.ok) {
        console.log("Authenticated.");
        login();

        console.log("Mapping:", auth_response.anonymousUserToUserIdMapping);
        if (auth_response.anonymousUserToUserIdMapping == null) {
          console.log("Mapping anonymous user to the userId");
          //update the existing user tag
          updateAnonymousUserService({ userId: auth_response.user._id });
        }

        // only generate a new profile if an anonymous profile was generated  before
        // and the current logged in user does not have any profileQuizCars already
        // Mostly the case for google users?
        const anonymous_profile = localStorage.getItem("anonymous-profile");
        if (
          anonymous_profile !== null &&
          auth_response.matchmaker?.profileQuizMaeTrims.length === 0
        ) {
          console.log("Will generate profile.");
          const anonymous_profile_json = JSON.parse(anonymous_profile);
          console.log("anony quiz data:", anonymous_profile_json.profileQuiz);

          const profile_response = await generateProfileService(
            anonymous_profile_json.profileQuiz,
            userSource,
            userSourceId
          );
          if (profile_response != null) {
            console.log("PROFILE FROM ANONY TO ACTUAL");
            setMatchmaker(profile_response.matchmaker);
            setUser(profile_response.user);
            setUserSource(profile_response.user.userSource);
            setUserSourceId(profile_response.user.userSourceId);
            setLastAction(PROFILE_GENERATED);
            localStorage.removeItem("anonymous-profile");
          }
        } else {
          const CCStatesLength =
            auth_response.user.comparisonChartUserStates.length;
          setMatchmaker(auth_response.matchmaker);
          setLastAction(MATCHMAKER_UPDATED);
          setUser(auth_response.user);
          setUserSource(auth_response.user.userSource);
          setUserSourceId(auth_response.user.userSourceId);
          if (CCStatesLength > 0) {
            setCurrentComparisonChartState(
              auth_response.user.comparisonChartUserStates.at(-1).maeTrimIdList
            );
          }
          setLastChartId(
            CCStatesLength > 0
              ? auth_response.user.comparisonChartUserStates.at(-1)._id
              : ""
          );
          setExtraVehicles(auth_response.user.addedExtraCars);
          setUserAddedFeatures(auth_response.user.userAddedExtraFeatures);
          setComparisonChartViewed(auth_response.user.comparisonChartViewed);
        }
      } else {
        setLastAction(NOT_AUTHENTICATED);
        console.log(auth_response.error);
      }
    } catch (error) {
      console.log("Error:", error);
    }
    setIsAuthContextLoading(false);
  };

  //Only use for logged in users
  const updateMatchmaker = async () => {
    setIsAuthContextLoading(true);
    try {
      const response = await fetch(`${ENDPOINT_AUTHENTICATE}/authenticate`, {
        method: "POST",
        headers: requestHeader,
        body: JSON.stringify({
          anonymousId: localStorage.getItem("anonymous-id"),
        }),
        credentials: "include",
      });

      const auth_response = await response.json();
      console.log(auth_response);

      if (auth_response.anonymousId !== undefined) {
        localStorage.setItem("anonymous-id", auth_response.anonymousId);
      }

      if (response.ok) {
        const CCStatesLength =
          auth_response.user.comparisonChartUserStates.length;
        setLastAction(MATCHMAKER_UPDATED);
        setMatchmaker(auth_response.matchmaker);
        if (CCStatesLength > 0) {
          setCurrentComparisonChartState(
            auth_response.user.comparisonChartUserStates.at(-1).maeTrimIdList
          );
        }
        setLastChartId(
          CCStatesLength > 0
            ? auth_response.user.comparisonChartUserStates.at(-1)._id
            : ""
        );
        setExtraVehicles(auth_response.user.addedExtraCars);
        setUserAddedFeatures(auth_response.user.userAddedExtraFeatures);
        setComparisonChartViewed(auth_response.user.comparisonChartViewed);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
    setIsAuthContextLoading(false);
  };

  useEffect(() => {
    console.log("Update Auth Context", triggerUpdateAuthContext);
    updateAuthContext();
  }, [triggerUpdateAuthContext]);

  useEffect(() => {
    const localStorageUserSource = localStorage.getItem("user-source");
    const localStorageUserSourceId = localStorage.getItem("user-source-id");

    if (localStorageUserSource !== null) {
      setUserSource(localStorageUserSource);
    }

    if (localStorageUserSourceId !== null) {
      setUserSourceId(localStorageUserSourceId);
    }

    if (localStorageUserSource == null && localStorageUserSourceId == null) {
      const params = new URLSearchParams(location.search);
      const sourceParam = params.get("source");
      const idParam = params.get("id");

      if (sourceParam) {
        setUserSource(sourceParam);
        localStorage.setItem("user-source", sourceParam);
      }
      if (idParam) {
        setUserSourceId(idParam);
        localStorage.setItem("user-source-id", idParam);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        matchmaker,
        lastAction,
        isAuthContextLoading,
        login,
        logout,
        updateAuthContext,
        setMatchmaker,
        setUser,
        printMatchmaker,
        setLastAction,
        setTriggerUpdateAuthContext,
        updateMatchmaker,
        currentComparisonChartState,
        setCurrentComparisonChartState,
        comparisonChartViewed,
        setComparisonChartViewed,
        extraVehicles,
        setExtraVehicles,
        userAddedFeatures,
        setUserAddedFeatures,
        lastChartId,
        setLastChartId,
        userSource,
        userSourceId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
