import "./DashboardSpeedDatingQuiz.scss";
import { useEffect, useState, useRef, useContext } from "react";
import likeIcon from "../../assets/icons/like.svg";
import dislikeIcon from "../../assets/icons/dislike.svg";
import SpeedDatingCard from "../speedDatingCard/SpeedDatingCard";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import SpeedDatingLoading from "../../pages/SpeedDatingLoading/SpeedDatingLoading";
import UpdatePopup from "../updatePopup/updatePopup";
import {
  SPEEDATING_RESULTS_SAVED,
  MATCHMAKER_UPDATED,
  NEW_SPEEDATING_SWIPE,
} from "./../../constants/lastActions";
import {
  dislikeMaeTrimService,
  likeMaeTrimService,
} from "../../services/speeddating";
import { ENDPOINT_SPEED_DATING } from "../../endpoints/url";
import requestHeader from "../../Header";

function DashboardSpeedDatingQuiz() {
  const { matchmaker, lastAction, setLastAction, updateMatchmaker } =
    useAuthContext();
  const cardRef = useRef(null);
  const navigate = useNavigate();

  const carMatches = matchmaker?.profileQuizMaeTrims.filter((car) => {
    return !car.liked && !car.disliked;
  });

  console.log(carMatches);
  const [liked, setLiked] = useState([]);
  const [disliked, setDisliked] = useState([]);

  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [checkCompareTabPopup, setCheckCompareTabPopup] = useState(false);
  const [speedDatingTimes, setSpeedDatingTimes] = useState(0);

  const checkCompareTabPopupText =
    "Keep swiping to find the cars you want to compare and when you are ready head over to the Compare tab. Don’t worry, you can always come back to speed dating to add more cars!";

  const handleSwipe = (direction, car) => {
    setCurrent((prevCurrent) => prevCurrent + 1);
    setLastAction(NEW_SPEEDATING_SWIPE);
    setSpeedDatingTimes(speedDatingTimes + 1);
    if (direction > 0) {
      likeMaeTrimService(car.vehicleInfo._maeTrimId);
      setLastAction(SPEEDATING_RESULTS_SAVED);
      // assuming right swipe is 'like'
      setLiked((prevLiked) => {
        if (!prevLiked.includes(car.vehicleInfo._maeTrimId)) {
          // Prevent duplicates
          const newLiked = [...prevLiked, car.vehicleInfo._maeTrimId];
          console.log(newLiked);
          return newLiked;
        }
        console.log(prevLiked);
        return prevLiked;
      });
    } else if (direction < 0) {
      dislikeMaeTrimService(car.vehicleInfo._maeTrimId);
      setLastAction(SPEEDATING_RESULTS_SAVED);
      // assuming left swipe is 'dislike'
      setDisliked((prevDisliked) => {
        if (!prevDisliked.includes(car.vehicleInfo._maeTrimId)) {
          // Prevent duplicates
          const newDisliked = [...prevDisliked, car.vehicleInfo._maeTrimId];
          console.log(newDisliked);
          return newDisliked;
        }
        console.log(prevDisliked);
        return prevDisliked;
      });
    }
  };

  useEffect(() => {
    if (speedDatingTimes === 3) {
      setCheckCompareTabPopup(true);
      setSpeedDatingTimes(0);
    }
  }, [speedDatingTimes]);

  useEffect(() => {
    if (current == carMatches.length && current != 0) {
      setIsLoading(true);
      setLastAction(SPEEDATING_RESULTS_SAVED);
      submit().then(() => {
        navigate("/matches");
      });
    }
  }, [current]);

  useEffect(() => {
    console.log("LAST ACTION:", lastAction);
    if (lastAction !== MATCHMAKER_UPDATED) updateMatchmaker();
  }, []);

  const submit = async () => {
    console.log("Submit function");
    let speedDatingResults = {
      likedMaeTrimIds: liked,
      dislikedMaeTrimIds: disliked,
    };

    console.log("speed dating results", speedDatingResults);

    try {
      const response = await fetch(`${ENDPOINT_SPEED_DATING}/savespeeddatingresults`, {
        method: "POST",
        headers: requestHeader,
        credentials: "include",
        body: JSON.stringify(speedDatingResults),
      });

      if (response.ok) {
        const res = await response.json();
        console.log("Updated matchmaker");
        console.log(res);
      } else {
        const errorData = await response.json();
        console.log("Failed:", errorData);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  if (isLoading) {
    <SpeedDatingLoading />;
  }

  return (
    <>
      {checkCompareTabPopup && (
        <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
          <UpdatePopup
            Popupshow={setCheckCompareTabPopup}
            main={checkCompareTabPopupText}
          />
        </div>
      )}
      <div className="speed-dating-dashboard">
        <div className="speed-dating-dashboard__header">
          <h2 className="header">Speed Dating</h2>
          <h1 className="subheader">Let’s Narrow Down Your Options</h1>
          <p className="speed-dating-dashboard__text">
            Match {Math.min(current + 1, carMatches.length)} out of{" "}
            {carMatches.length}{" "}
          </p>
        </div>
        <div className="speed-dating-dashboard__main">
          <div
            className="options dislike_desktop"
            onClick={() => cardRef.current && cardRef.current.handleDislike()}
          >
            <img className="options__icon" src={dislikeIcon} alt="" />
            <p className="options__text">Not for me</p>
          </div>
          <div className="speed-dating-dashboard__cards">
            {current < carMatches.length && (
              <SpeedDatingCard
                key={carMatches[current].vehicleInfo._id}
                ref={cardRef}
                card={carMatches[current]}
                handleSwipe={(direction) =>
                  handleSwipe(direction, carMatches[current])
                }
              />
            )}
          </div>
          <div
            className="options like_desktop"
            onClick={() => cardRef.current && cardRef.current.handleLike()}
          >
            <img className="options__icon" src={likeIcon} alt="" />
            <p className="options__text">I like this!</p>
          </div>
          <div className="options-row">
            <div
              className="dislike_mobile"
              onClick={() => cardRef.current && cardRef.current.handleDislike()}
            >
              <img className="options__icon" src={dislikeIcon} alt="" />
              <p className="options__text">Not for me</p>
            </div>

            <div
              className="like_mobile"
              onClick={() => cardRef.current && cardRef.current.handleLike()}
            >
              <img className="options__icon" src={likeIcon} alt="" />
              <p className="options__text">I like this!</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSpeedDatingQuiz;
