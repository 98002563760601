export const labelTooltipData = {
    best_trim : {
        tooltipClassId : `tooltip_best_trim`,
        labelStyleClass : 'label-text',
        label : "Best Trim",
        tooltipInfo : ""
    },
    base_MSRP : {
        tooltipClassId : `tooltip_base_MSRP`,
        labelStyleClass : 'label-text',
        label : "Base MSRP",
        tooltipInfo : "This does not include taxes, dealer fees, extra colours,shipping costs etc."
    },
    compatibility : {
        tooltipClassId : `tooltip_compatibility`,
        labelStyleClass : 'label-text',
        label : "Compatibility",
        tooltipInfo : `We calculate our compatibility through a combination of vehicle
        information prioritizing price and features cause we want to get
        you the most value for least amount of money`
    },
    lifestyle : {
        tooltipClassId : `tooltip_lifestyle`,
        labelStyleClass : 'label-text',
        label : "Big Families",
        tooltipInfo : "6+ seats"
    },
    driving_condition : {
        tooltipClassId : `tooltip_driving_condition`,
        labelStyleClass : 'label-text',
        label : "Driving in Snowy Conditions",
        tooltipInfo: `This vehicle has AWD or 4x4 drive type meaning it has power
        running to all four wheels which is recommended for people who
        love their road trips to Whistler.`
    },
    tall_passenger : {
        tooltipClassId : `tooltip_tall_passenger`,
        labelStyleClass : 'label-text',
        label : "Tall Passenger",
        tooltipInfo : "This vehicle has head room and leg room greater than 38 inches."
    }

}