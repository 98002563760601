import "./updatePopup.scss";
import { useEffect } from "react";
import X from "../../assets/images/X.svg";

function UpdatePopup({ popupShow, main }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      popupShow(false);
    }, 5000);
  });
  return (
    <div className="updatePopupLayout">
      <div className="loading-bar" />
      <img
        src={X}
        alt="exit"
        className="updatePopupLayout__exit"
        onClick={() => {
          popupShow({show : false});
        }}
      />
      <p className="updatePopupLayout__text">{main}</p>
    </div>
  );
}

export default UpdatePopup;
