import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./DashboardAuthenticate.scss";
import dashboardSignInImg from "../../assets/images/login-icon.svg";
import dashboardSignUpImg from "../../assets/images/dashboard-authenticate.svg";
import tickImg from "../../assets/images/tickImg.svg";
import speedDatingImg from "../../assets/images/signin-speedDating.svg";

function DashboardAuthenticate() {
  const location = useLocation();

  let displayText;
  let currentImg;
  let showTickList = false; 
  let showAltImg = false;

  if (location.pathname === "/signup") {
    showTickList = true;
    showAltImg = true; 
  }

  if (showTickList) {
    displayText = "Welcome to Speed Dating!";
    currentImg = speedDatingImg;
  } else {
    switch (location.pathname) {
      case "/signin":
        displayText = "Sign In to Your Account";
        currentImg = dashboardSignInImg;
        break;
      case "/signup":
        displayText = "Create Your Account";
        currentImg = dashboardSignUpImg;
        break;
      default:
        displayText = "Welcome to MAE";
    }
  }

  return (
    <>
      <div className="authenticate-filler">
        <div className="dashboard-authenticate">
          {!showTickList ? (
            <div>
              <h1 className="dashboard-authenticate__text">{displayText}</h1>
              <img
                className="dashboard-authenticate__img"
                src={currentImg}
                alt=""
              />
            </div>
          ) : (
            <div className="tick-list-container">
              <h1 className="tick-list-heading">Your Account with Mae</h1>
              <div className="tick-list">
                <div className="tick-sentence">
                  <img className="tickImg" src={tickImg} alt="" />
                  <h2 className="tick-text">Meet all your matches</h2>
                </div>
                <div className="tick-sentence">
                  <img className="tickImg" src={tickImg} alt="" />
                  <h2 className="tick-text">
                    See which vehicles score highest for your needs
                  </h2>
                </div>
                <div className="tick-sentence">
                  <img className="tickImg" src={tickImg} alt="" />
                  <h2 className="tick-text">Compare cars your way</h2>
                </div>
                <div className="tick-sentence">
                  <img className="tickImg" src={tickImg} alt="" />
                  <h2 className="tick-text">40+ ways to personalize</h2>
                </div>
                <div className="tick-sentence">
                  <img className="tickImg" src={tickImg} alt="" />
                  <h2 className="tick-text">
                    Decision charts with only what YOU want to compare!
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
        {showAltImg && (
          <img
            className="dashboard-authenticate__imgalt"
            src={currentImg}
            alt=""
          />
        )}
      </div>
    </>
  );
}

export default DashboardAuthenticate;
