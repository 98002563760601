export const BUTTON_CLICK_EVENT_TYPE = "BUTTON_CLICK_EVENT_TYPE";
export const PAGE_CLICK_EVENT_TYPE = "PAGE_CLICK_EVENT_TYPE";
export const PAGE_VIEW_TIME_EVENT_TYPE = "PAGE_VIEW_TIME_EVENT_TYPE";
export const MDP_CLICK_EVENT_TYPE = "MDP_CLICK_EVENT_TYPE";
export const MDP_VIEW_TIME_EVENT_TYPE = "MDP_VIEW_TIME_EVENT_TYPE";
export const COMPARISON_CHART_VIEW_TIME_EVENT_TYPE =
  "COMPARISON_CHART_VIEW_TIME_EVENT_TYPE";
export const ZERO_MATCHES_EVENT_TYPE = "ZERO_MATCHES_EVENT_TYPE";
export const OFFER_BANNER_FORM_SUBMISSION_EVENT_TYPE = "OFFER_BANNER_FORM_SUBMISSION_EVENT_TYPE"

