export const ENDPOINT_AUTHENTICATE = "/api/auth"
export const ENDPOINT_PROFILE = "/api/profile"
export const ENDPOINT_SPEED_DATING = "/api/speeddating"
export const ENDPOINT_MDP = "/api/mdp"
export const ENDPOINT_ACCOUNT = "/api/account"
export const ENDPOINT_COMPARISON_CHART = "/api/comparisonchart"
export const ENDPOINT_USER_EVENT = "/api/userEvent"



