import "./MatchesList.scss";
import { useState } from "react";
import { matchRoutes, useNavigate } from "react-router-dom";
import ButtonGradient from "../buttonGradient/ButtonGradient.jsx";
import SelectCarStatus from "../selectCarStatus/SelectCarStatus.jsx";
import SelectCarDropdown from "../selectCarDropdown/selectCarDropdown.jsx";
import ExtraCarSection from "../extraCarSection/extraCarSection.jsx";
import {
  trackAndUpdateComparisonChartInfo,
  updateComparisonChartInfo,
} from "../../services/comparisonchart.js";
import { trackUserEventService } from "../../services/userEvent.js";
import { BUTTON_CLICK_EVENT_TYPE } from "../../constants/userEvents.js";
import { useAuthContext } from "../../contexts/authContext.jsx";
import { SPEEDATING_RESULTS_SAVED } from "../../constants/lastActions.js";
import {
  dislikeMaeTrimService,
  likeMaeTrimService,
} from "../../services/speeddating.js";
import CarCard from "../carCard/carCard.jsx";
import Banner from "../banner/banner.jsx";

function MatchesList({ selectionEnabled = false }) {
  const navigate = useNavigate();
  function getLikedCarsIds() {
    const likedCars = matchmaker?.profileQuizMaeTrims.filter((car) => {
      return car.liked;
    });
    return likedCars.map((car) => {
      return car._maeTrimId;
    });
  }

  function getDislikedCarsIds() {
    const dislikedCars = matchmaker?.profileQuizMaeTrims.filter((car) => {
      return car.disliked;
    });
    return dislikedCars.map((car) => {
      return car._maeTrimId;
    });
  }

  const {
    matchmaker,
    setLastAction,
    currentComparisonChartState,
    setCurrentComparisonChartState,
    lastChartId,
    setLastChartId,
    extraVehicles,
    setExtraVehicles
  } = useAuthContext();

  const [carsToCompareId, setCarsToCompareId] = useState(
    currentComparisonChartState
  );

  console.log("cars to compare",carsToCompareId)
  const [likedCarsMaeTrimId, setLikedCarsMaeTrimId] =
    useState(getLikedCarsIds());
  const [dislikedCarsMaeTrimId, setDislikedCarsMaeTrimId] =
    useState(getDislikedCarsIds());

  const likeCar = (id) => {
    const stateCopy = [...likedCarsMaeTrimId];
    const carIdIndex = stateCopy.indexOf(id);
    if (carIdIndex === -1) {
      const dislikedCarIndex = dislikedCarsMaeTrimId.indexOf(id);
      if (dislikedCarIndex !== -1) {
        dislikedCarsMaeTrimId.splice(dislikedCarIndex, 1);
        setDislikedCarsMaeTrimId(dislikedCarsMaeTrimId);
      }
      stateCopy.push(id);
      likeMaeTrimService(id);
    } else {
      stateCopy.splice(carIdIndex, 1);
    }
    setLikedCarsMaeTrimId(stateCopy);
    setLastAction(SPEEDATING_RESULTS_SAVED);
  }

  function dislikeCar(id) {
    const stateCopy = [...dislikedCarsMaeTrimId];
    const carIdIndex = stateCopy.indexOf(id);
    if (carIdIndex !== -1) {
      stateCopy.splice(carIdIndex, 1);
    } else {
      const likedCarIndex = likedCarsMaeTrimId.indexOf(id);
      if (likedCarIndex !== -1) {
        likedCarsMaeTrimId.splice(likedCarIndex, 1);
        setLikedCarsMaeTrimId(likedCarsMaeTrimId);
      }
      stateCopy.push(id);
      dislikeMaeTrimService(id);
    }
    setDislikedCarsMaeTrimId(stateCopy);
    setLastAction(SPEEDATING_RESULTS_SAVED);
  }

  async function goToComparePage() {
    trackUserEventService(
      BUTTON_CLICK_EVENT_TYPE,
      "Compare Button Clicked in Matches Page"
    );
    setCurrentComparisonChartState(carsToCompareId);
    async function callToUpdateCCState() {
      const res = await updateComparisonChartInfo(carsToCompareId,extraVehicles);
      if (res.comparisonChartUserState) {
        setLastChartId(res.comparisonChartUserState._id);
      }
    }
    await callToUpdateCCState();
    navigate("/compare");
  }

  function callForTrackCC(id, type) {
    console.log(lastChartId);
    trackAndUpdateComparisonChartInfo({
      interactionType: type,
      chartId: lastChartId,
      additionalDetails: {
        carId: id,
      },
    });
  }

  function carSelection(id) {
    const carsMaeTrimIds = [...carsToCompareId];
    const carIdIndex = carsMaeTrimIds.indexOf(id);
    console.log(carIdIndex,"carIdIndex");
    console.log(id,"id");
    if (carIdIndex === -1) {
      if (carsMaeTrimIds.length >= 20) return;
      carsMaeTrimIds.push(id);
    } else {
      carsMaeTrimIds.splice(carIdIndex, 1);
      const extraVehiclesFiltered = extraVehicles.filter((element) => {
        return element.vehicleInfo._maeTrimId !== id;
      });
      console.log(extraVehicles,"extraVehicles");
      console.log(extraVehiclesFiltered,"extraVehiclesFiltered");
      setExtraVehicles(extraVehiclesFiltered);
    }
    if (selectionEnabled) {
      callForTrackCC(id, carIdIndex === -1 ? "select" : "deselect");
    }
    setCarsToCompareId(carsMaeTrimIds);
  }

  return (
    <div className="matches-list">
      <div className={`matches-list__selection ${selectionEnabled ? "padding-hide" : ""}`}>
        {selectionEnabled ? (
          <div className="selection_status_container">
            <SelectCarStatus
              // remove={setSelectionEnabled}
              setCarsToCompareId={setCarsToCompareId}
              number={carsToCompareId.length}
              setExtraVehicles={setExtraVehicles}
              // selectedConstant={selectionEnabled}
            />
            <div className="align-middle">
              <ButtonGradient
                text="Compare"
                style={carsToCompareId.length >= 1 ? "" : "disabled"}
                onClick={() => {
                  return carsToCompareId.length >= 1 ? goToComparePage() : null;
                }}
              />
            </div>
          </div>
        ) : (
        <Banner 
        onClick={() => navigate("/compare")}  
        heading = "The #1 Comparison Tool for truly informed car buying decisions." 
        text="Compare car options the smart way"
        buttonText="Compare"/>
        )}
      </div>
      {selectionEnabled && (
        <SelectCarDropdown
          extraVehicles={extraVehicles}
          setExtraVehicles={setExtraVehicles}
          carSelection={carSelection}
          carsToCompareId={carsToCompareId}
        />
      )}

      {selectionEnabled && extraVehicles.length > 0 && (
        <h2 className="matches-list__title">Added Cars</h2>
      )} 
      {selectionEnabled && (
        <ExtraCarSection
          extraVehicles={extraVehicles}
          setExtraVehicles={setExtraVehicles}
          carSelection={carSelection}
        />
      )} 

      {selectionEnabled && (
        <h2 className="matches-list__title">All Your Matches</h2>
      )}
        <div className={`matches-list__wrapper ${selectionEnabled ? "grow-space" : ""}`}>
        {matchmaker.profileQuizMaeTrims.map((vehicle) => {
          const maeTrimId = vehicle._maeTrimId;
          const isSelected =
            selectionEnabled && carsToCompareId.includes(maeTrimId);
          const isSelectable =
            selectionEnabled && !isSelected && carsToCompareId.length < 20;
          
          return dislikedCarsMaeTrimId.includes(maeTrimId) ? null : (
              <CarCard
              key = {maeTrimId}
              carInfo={vehicle} 
              cardSelectionOnClick={selectionEnabled? carSelection:() => {}}
              isCardSelected={isSelected} 
              isCardSelectable={isSelectable}  
              isCardLinkingToMDP={!selectionEnabled} 
              likeOnClick={likeCar} 
              dislikeOnClick={dislikeCar}
              isDisliked={dislikedCarsMaeTrimId.includes(maeTrimId)}
              isLiked={likedCarsMaeTrimId.includes(maeTrimId)}
              showLikeDislike={!selectionEnabled}
              isCompatibilityVisible={true}
              />
          );})}
      </div>

      {dislikedCarsMaeTrimId.length > 0 && <h2 className="matches-list__title">Friendzoned Matches</h2>}
  

      <div className={`matches-list__wrapper ${selectionEnabled ? "grow-space" : ""}`}>
        {matchmaker.profileQuizMaeTrims.map((vehicle) => {
          
          const maeTrimId = vehicle._maeTrimId;
          const isSelected =
            selectionEnabled && carsToCompareId.includes(maeTrimId);
          const isSelectable =
            selectionEnabled && !isSelected && carsToCompareId.length < 20;
          
            return dislikedCarsMaeTrimId.includes(maeTrimId) ? (
              <CarCard
              key = {maeTrimId}
              carInfo={vehicle} 
              cardSelectionOnClick={selectionEnabled? carSelection:() => {}}
              isCardSelected={isSelected} 
              isCardSelectable={isSelectable}  
              isCardLinkingToMDP={!selectionEnabled} 
              likeOnClick={likeCar} 
              dislikeOnClick={dislikeCar}
              isDisliked={dislikedCarsMaeTrimId.includes(maeTrimId)}
              isLiked={likedCarsMaeTrimId.includes(maeTrimId)}
              showLikeDislike={!selectionEnabled}
              isCompatibilityVisible={true}
              />
            ) : null;
          
        })}
      </div>
    </div>
  );
}

export default MatchesList;
