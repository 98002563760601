import './popupDisplay.scss'
import UpdatePopup from "../updatePopup/updatePopup"


export default function PopupDisplay( { popupUpdate, setPopupUpdate }) {

    const popupTextObj =   {
        submittedPopupText : "Thanks! A car buying bestie from Mae will be in touch! ",
        mobileViewNotReady : `This Feature is only available for Desktop screens. To enable
        this feature in your mobile screens and tablet kindly view it by enabling desktop site`,
        addedFeatureText : "Your Selected features are added below!"
    }

    return (
        <>
            {popupUpdate.show ? <div className="popup_position">
                <UpdatePopup 
                    popupShow={setPopupUpdate}
                    main={popupTextObj[popupUpdate.textId]}
                />
            </div> : null}
        </>
        
    )
}