import './dividerComparisonChart.scss';
import { ReactComponent as DividerLine } from "../../assets/icons/divider.svg";


export default function DividerForCarsComparison({ carsToCompareList, opacityValue }) {
    if (opacityValue) {
      return (
        <div className="chart-container" style={{ height: "5px" }}>
          <DividerLine className="invisible" />
        </div>
      );
    }
    return (
      <div className="chart-container" style={{ height: "5px" }}>
        {carsToCompareList &&
          carsToCompareList?.length > 0 &&
          carsToCompareList.map((_,index) => {
            const marginClass = index === 0 ? "" : "minus-left-margin-divider";
            return <DividerLine key={index} className={marginClass} />;
          })}
      </div>
    );
  }