import "./selectCarDropdown.scss";
import { makesandmodels } from "../../data/makesandmodels";
import { useState, useEffect } from "react";
import { generateBestMaeTrimIdFromMake } from "../../services/mdp.js";
import { useAuthContext } from "../../contexts/authContext";
import ButtonPlain from "../buttonPlain/ButtonPlain.jsx";

export default function SelectCarDropdown({
  extraVehicles,
  setExtraVehicles,
  carSelection,
  carsToCompareId,
}) {
  const { matchmaker } = useAuthContext();
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [makesandmodelsObj, setMakesAndModelsObj] = useState(makesandmodels);

  useEffect(() => {
    const sortAlphabetically = (arr) => arr.sort((a, b) => a.localeCompare(b));

    const sortedKeys = Object.keys(makesandmodels).sort();

    const sortedMakesAndModels = {};
    sortedKeys.forEach((key) => {
      sortedMakesAndModels[key] = sortAlphabetically(makesandmodels[key]);
    });

    setMakesAndModelsObj(sortedMakesAndModels);
  }, []);

  const submitCar = async () => {
    const response = await generateBestMaeTrimIdFromMake(
      carMake,
      carModel,
      matchmaker?.profileQuiz[7].selectedOptions[0]
    );
    if (!response) {
      return;
    }
    const present = carsToCompareId.includes(
      response.maeTrimMatch.vehicleInfo._maeTrimId
    );
    if (!present) {
      extraVehicles.push(response.maeTrimMatch);
      setExtraVehicles(extraVehicles);
      carSelection(response.maeTrimMatch.vehicleInfo._maeTrimId);
    }
    setCarMake("");
    setCarModel("");
  };

  const handleMakeChange = (make) => {
    setCarMake(make);
    setCarModel("");
  };

  const handleModelChange = (model) => {
    setCarModel(model);
  };

  return (
    <div className="container">
      <h4>Add Extra Cars</h4>
      <div className="dropdown-container">
        <div className="dropdown-group">
          <label htmlFor="carMake">Car Make</label>
          <select
            value={carMake}
            onChange={(e) => handleMakeChange(e.target.value)}
            className="dropdown"
          >
            <option value="" disabled hidden>
              Select Make
            </option>
            {Object.keys(makesandmodelsObj).map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="dropdown-group">
          <label htmlFor="carMake">Car Model</label>
          <select
            value={carModel}
            onChange={(e) => handleModelChange(e.target.value)}
            className="dropdown"
          >
            <option value="">Select Model</option>
            {makesandmodelsObj[carMake] &&
              makesandmodelsObj[carMake].map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div style={{width:"20%"}}>
      <ButtonPlain onClick={submitCar}>Add Car</ButtonPlain>
      </div>
       
    </div>
  );
}
