/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-comment-textnodes */
// eslint-disable jsx-a11y/anchor-is-valid
import "./comparisonChart.scss";
import { useEffect, useState, React, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext.jsx";
import FeatureRequirementIcon from "../FeatureRequirementIcon/FeatureRequirementIcon.jsx";
import { formatToOneDecimal } from "../../utils/formatUtils.jsx";
import { ReactComponent as AddCarSign } from "../../assets/icons/add-cars.svg";
import { ReactComponent as Bin } from "../../assets/icons/bin.svg";
import {
  updateComparisonChartInfo,
  trackAndUpdateComparisonChartInfo,
} from "../../services/comparisonchart.js";
import { ReactComponent as AddFeaturesButton } from "../../assets/icons/add-features.svg";
import {
  ANONYMOUS_PROFILE_GENERATED,
  MATCHMAKER_UPDATED,
} from "../../constants/lastActions.js";
import { getCarsInfoFromId } from "../../services/mdp.js";
import LoadingScreen from "../loadingScreen/loadingScreen.jsx";
import {
  COMPARISON_CHART_VIEW_TIME_EVENT_TYPE,
} from "../../constants/userEvents.js";
import { trackUserEventService } from "../../services/userEvent.js";
import ComparisonChartCompatibilitySection from "../comparisonChartCompatibilitySection/comparisonChartCompatibilitySection.jsx";
import LabelWithTooltip from "../labelTooltip/labelTooltip.jsx";
import DividerForCarsComparison from "../dividerComparisonChart/dividerComparisonChart.jsx";
import { labelTooltipData } from '../labelTooltip/labelTooltipData.js';

export default function ComparisonChart({ featuresSelected, featureCtaClicked, setSwipeOn  }) {
  const {
    matchmaker,
    currentComparisonChartState,
    setCurrentComparisonChartState,
    lastChartId,
    setLastChartId,
    updateMatchmaker,
    lastAction,
    extraVehicles,
    setExtraVehicles,
    isAuthContextLoading,
    userSource,
  } = useAuthContext();
  const navigate = useNavigate();

  console.log("user source in comparison chart", userSource);

  const [carsToCompareList, setCarsToCompareList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageViewStartTime, setPageViewStartTime] = useState(Date.now());
  const chartValueContainerRef = useCallback((node) => {
    if(node !== null) {
      const needToSwipe = node.scrollWidth > node.offsetWidth + 100;
      setSwipeOn(needToSwipe);
    } 
  });  
  let isUnmounting = false;


  useEffect(() => {
    console.log("LAST ACTION:", lastAction);
    if (
      lastAction !== MATCHMAKER_UPDATED &&
      lastAction !== ANONYMOUS_PROFILE_GENERATED
    )
      updateMatchmaker();
  }, []);

  const getCarDetails = async (carsToCompareIds) => {
    if (matchmaker?.profileQuizMaeTrims.length > 0) {
      return await getCarsInfoFromId(
        carsToCompareIds,
        matchmaker?.profileQuizMaeTrims[0].totalFeatures
      );
    }
  };

  useEffect(() => {
    const fetchCarDetails = async () => {
      window.scrollTo(0, 0);
      console.log(
        currentComparisonChartState,
        "currentComparisonChartsState",
        lastChartId,
        "lastChartId"
      );

      try {
        console.log(
          lastChartId,
          currentComparisonChartState,
          currentComparisonChartState
        );
        const res = await getCarDetails(currentComparisonChartState);
        console.log(res, "res");
        if (res.carsInfo) {
          const carsToCompare = res.carsInfo;
          setCarsToCompareList(carsToCompare);
        } else {
          console.log("To Matches");
          navigate("/matches");
        }
      } catch (error) {
        console.error("Error fetching car details:", error);
        // Handle the error appropriately here
      } finally {
        setLoading(false);
      }
    };

    if (!isAuthContextLoading) {
      fetchCarDetails();
    }
  }, [isAuthContextLoading]);

  
  useEffect(() => {
    return () => {
      if (isUnmounting) {
        const pageViewEndTime = Date.now();
        const timeSpentInSeconds = (pageViewEndTime - pageViewStartTime) / 1000;
        trackUserEventService(COMPARISON_CHART_VIEW_TIME_EVENT_TYPE, {
          timeSpent: timeSpentInSeconds,
        });
      }

      isUnmounting = true;
    };
  }, []);

  function adjustHeight(eptypeDivs, class_name) {
    const labelDiv = document.querySelector(class_name);
    let maxHeight = 0;

    eptypeDivs.forEach((div) => {
      // console.log(div.offsetHeight,div,"div");
      const height = div.offsetHeight;
      maxHeight = Math.max(maxHeight, height);
    });

    eptypeDivs.forEach((div) => {
      div.style.height = `${maxHeight}px`;
    });

    labelDiv.style.height = `${maxHeight}px`;
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const eptypeDivs = document.querySelectorAll(".eptypevalue");
      const carNameDivs = document.querySelectorAll(".carName");
      const recommendedTrimDivs =
        document.querySelectorAll(".recommended_value");
      const compatibilityDivs = document.querySelectorAll(
        ".compatibility_value"
      );
      const moreFeatureDivs = document.querySelectorAll(".more-features"); 
      if (eptypeDivs.length > 0) {
        adjustHeight(eptypeDivs, ".eptype");
      }
      if (carNameDivs.length > 0) {
        adjustHeight(carNameDivs, ".carNameDiv");
      }
      if (recommendedTrimDivs.length > 0) {
        adjustHeight(recommendedTrimDivs, ".recommended_label");
      }
      if (compatibilityDivs.length > 0) {
        adjustHeight(compatibilityDivs, ".compatibility_label");
      }
      if (moreFeatureDivs.length > 0) {
        adjustHeight(moreFeatureDivs, ".more_features_connected_label");
      }
    });

    const config = { childList: true, subtree: true };
    const targetNode = document.getElementById("root");

    if (targetNode) {
      observer.observe(targetNode, config);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);


  async function callToUpdateCCState(remainingCarIds, remainingExtraCars) {
    const res = await updateComparisonChartInfo(
      remainingCarIds,
      remainingExtraCars
    );
    if (res.comparisonChartUserState) {
      setLastChartId(res.comparisonChartUserState._id);
    }
  }

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  function formatCapitalization(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  async function removeCar(id) {
    let carList = [...carsToCompareList];
    const remainingCarIds = [];
    console.log(id, "id");
    carList = carList.filter((car, i) => {
      if (car._maeTrimId !== id) remainingCarIds.push(car._maeTrimId);
      return car._maeTrimId !== id;
    });
    const remainingExtraCars = extraVehicles.filter((car) => {
      console.log(car.vehicleInfo._maeTrimId);
      return car.vehicleInfo._maeTrimId !== id;
    });
    setCarsToCompareList(carList);
    setCurrentComparisonChartState(remainingCarIds);
    setExtraVehicles(remainingExtraCars);
    await callToUpdateCCState(remainingCarIds, remainingExtraCars);
    await trackAndUpdateComparisonChartInfo({
      chartId: lastChartId,
      interactionType: "delete",
      additionalDetails: {
        carId: id,
      },
    });
  }

  if (loading && !isAuthContextLoading) {
    return <LoadingScreen />;
  }

  return (
      <div className="chart-layout">
        <div className="compare-label-layout">
          <h3 className="chart_car_label carNameDiv">Cars</h3>
          <AddCarSign
            className="add_car_sign"
            onClick={() => {
              navigate("/add-cars");
            }}
          />

          <div className="recommended_label">
            <LabelWithTooltip {...labelTooltipData.best_trim} />
          </div>

          <LabelWithTooltip {...labelTooltipData.base_MSRP} />

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="compatibility_label">
            <LabelWithTooltip {...labelTooltipData.compatibility} />
          </div>

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="label-group">
            <p className="label-text-yellow-heading">Requirements</p>
            <p className="label-text eptype">Engine Power Type</p>
            <p className="label-text">Drive Type</p>
          </div>

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="label-group desired-features">
            <p className="label-text-yellow-heading">Your Desired Features</p>
            {carsToCompareList?.length !== 0 &&
              carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                <p key={index} className="label-text">
                  {formatCapitalization(feature)}
                </p>
              ))}
          </div>

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="label-group other-features">
            <p className="label-text-yellow-heading more_features_connected_label">
              Other Features
            </p>
            {featuresSelected.map((feature, index) => (
              <div key={index}>
                <p className="label-text">{formatCapitalization(feature)}</p>
              </div>
            ))}
          </div>

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="label-group lifestyle">
            <p className="label-text-yellow-heading">Lifestyle</p>
            <LabelWithTooltip {...labelTooltipData.lifestyle} />
            <LabelWithTooltip {...labelTooltipData.driving_condition} />
            <LabelWithTooltip {...labelTooltipData.tall_passenger} />
            <p className="label-text">A to B City Commuting</p>
            <p className="label-text">Eco Conscious</p>
            <p className="label-text">Large Trunk Needs</p>
          </div>

          <DividerForCarsComparison
            carsToCompareList={carsToCompareList}
            opacityValue={true}
          />

          <div className="label-group">
            <p className="label-text-yellow-heading">Dimensions</p>
            <p className="label-text">Trunk Volume</p>
            <p className="label-text">1st Head Room</p>
            <p className="label-text">2nd Head Room</p>
            <p className="label-text">1st Row Leg Room</p>
            <p className="label-text">2nd Row Leg Room</p>
          </div>
        </div>

        <div ref={chartValueContainerRef} className="compare-values-layout">
          {/*name*/}
          <div className="chart-container carName">
            {carsToCompareList.map((vehicle, index) => (
              <div className="name-with-bin" key={index}>
                <h3 className="chart_car_label">
                  {`${vehicle.vehicleInfo.make} ${vehicle.vehicleInfo.model}`}
                </h3>
                <Bin onClick={() => removeCar(vehicle._maeTrimId)} />
              </div>
            ))}
          </div>

          {/* image */}
          <div className="chart-container">
            {carsToCompareList.map((vehicle, index) => (
              <div className="img-block" key={index}>
                <img
                  className="img-car"
                  src={vehicle.vehicleInfo.amazonImage}
                  alt={vehicle.calculatedMDPFeatures.maeTrimTitle}
                />
              </div>
            ))}
          </div>

          {/* Recommended Trim for the car */}
          <div className="chart-container recommended_value">
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="recommended-trim-block">
                <h4 className="recommended-trim-block--text">
                  {vehicle.vehicleInfo.styleDescription}
                </h4>
              </div>
            ))}
          </div>

          {/* Price section of the car */}
          <div className="chart-container">
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="price-block chart_value_text">
                <p className="price-block--currency">$</p>
                {formatPrice(vehicle.vehicleInfo.baseMSRP)}
              </div>
            ))}
          </div>
          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* Compatibility for the cars */}
          <div className="chart-container compatibility_value">
            {carsToCompareList.map((vehicle, index) => (
              <ComparisonChartCompatibilitySection
                key={index}
                vehicle={vehicle}
              />
            ))}
          </div>

          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* Requirement for the car */}
          <div className="chart-container">
            {carsToCompareList.map((vehicle, index) => (
              <div key={index} className="label-group">
                <p
                  className="label-text-yellow-heading"
                  style={{ opacity: "0" }}
                >
                  Requirements
                </p>
                <p
                  className="chart_value_text eptypevalue"
                  style={{ display: "block" }}
                >
                  {vehicle.calculatedMDPFeatures.engine.map(
                    (powerType, i) => (
                      <span key={i}>
                        {powerType === "PHEV" && "Plug-In Hybrid Gasoline"}
                        {powerType === "HEV" && "Hybrid Gasoline"}
                        {powerType !== "PHEV" &&
                          powerType !== "HEV" &&
                          powerType}{" "}
                        {"Engine"}
                        {i <
                          vehicle.calculatedMDPFeatures.engine?.length -
                            1 && <span>, </span>}
                      </span>
                    )
                  )}
                </p>
                <div className="drive-type-container">
                  <LabelWithTooltip
                    tooltipClassId={`tooltip_drive_type_${index + 1}`}
                    labelStyleClass={"chart_value_text"}
                    label={vehicle.vehicleInfo.driveType}
                    tooltipInfo={
                      vehicle.vehicleInfo.driveType === "AWD"
                        ? "All Wheel Drive"
                        : vehicle.vehicleInfo.driveType === "FWD"
                          ? "Forward Wheel Drive"
                          : "4 Wheel Drive"
                    }
                  />
                </div>
              </div>
            ))}
          </div>

          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* Your desired features for the car */}
          <div className="chart-container">
            <div className="label-group expanded_mobile_width">
              <div style={{ opacity: "0" }}>
                <FeatureRequirementIcon requirementPresent={true} />
              </div>
              {carsToCompareList?.length !== 0 &&
                carsToCompareList[0]?.totalFeatures.map((feature, index) => (
                  <div className="chart-feature-inner-flex" key={index}>
                    {carsToCompareList.map((vehicle, index) => {
                      return vehicle.vehicleInfo.maeFeatures[feature] ===
                        true ? (
                        <FeatureRequirementIcon
                          requirementPresent={true}
                          key={index}
                        />
                      ) : (
                        <FeatureRequirementIcon
                          requirementPresent={false}
                          key={index}
                        />
                      );
                    })}
                  </div>
                ))}
            </div>
          </div>

          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* Your other features for the car */}
          <div className="chart-container">
            <div className="label-group expanded_mobile_width">
              <div className="more-features" onClick={featureCtaClicked}>
                <span className="more-features__add">
                  <AddFeaturesButton />
                </span>
                <span className="more-features__text">Add more Features</span>
              </div>
              {featuresSelected.map((feature, index) => (
                <div className="chart-feature-inner-flex" key={index}>
                  {carsToCompareList.map((vehicle) => {
                    return vehicle.vehicleInfo.maeFeatures[feature] ===
                      true ? (
                      <FeatureRequirementIcon
                        requirementPresent={true}
                        key={`${vehicle._maeTrimId}${index}`}
                      />
                    ) : (
                      <FeatureRequirementIcon
                        requirementPresent={false}
                        key={`${vehicle._maeTrimId}${index}`}
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </div>

          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* Lifestyle section of the car */}
          <div className="chart-container">
            <div className="label-group expanded_mobile_width">
              <div style={{ opacity: "0" }}>
                <FeatureRequirementIcon requirementPresent={true} />
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => {
                  return vehicle.calculatedMDPFeatures.seats > 5 ? (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  ) : (
                    <FeatureRequirementIcon
                      requirementPresent={false}
                      key={vehicle._maeTrimId}
                    />
                  );
                })}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => {
                  return vehicle.vehicleInfo.driveType === "AWD" ||
                    vehicle.vehicleInfo.driveType === "4x4" ? (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  ) : (
                    <FeatureRequirementIcon
                      requirementPresent={false}
                      key={vehicle._maeTrimId}
                    />
                  );
                })}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <FeatureRequirementIcon
                    requirementPresent={true}
                    key={vehicle._maeTrimId}
                  />
                ))}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => {
                  return vehicle.calculatedMDPFeatures?.headRoomFirstRow >
                    38 &&
                    vehicle.calculatedMDPFeatures?.legRoomFirstRow > 38 ? (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  ) : (
                    <FeatureRequirementIcon
                      requirementPresent={false}
                      key={vehicle._maeTrimId}
                    />
                  );
                })}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => {
                  return vehicle.calculatedMDPFeatures.engine.includes(
                    "Gasoline"
                  ) ||
                    vehicle.calculatedMDPFeatures.engine.includes(
                      "Diesel"
                    ) ? (
                    <FeatureRequirementIcon
                      requirementPresent={false}
                      key={vehicle._maeTrimId}
                    />
                  ) : (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  );
                })}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => {
                  return vehicle.calculatedMDPFeatures.cargo > 20 ||
                    vehicle.vehicleInfo.maeSegment === "SUV" ||
                    vehicle.vehicleInfo.maeCargoCategory === "L" ? (
                    <FeatureRequirementIcon
                      requirementPresent={true}
                      key={vehicle._maeTrimId}
                    />
                  ) : (
                    <FeatureRequirementIcon
                      requirementPresent={false}
                      key={vehicle._maeTrimId}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <DividerForCarsComparison carsToCompareList={carsToCompareList} />

          {/* cc-dimensions for the car */}
          <div className="chart-container">
            <div className="label-group expanded_mobile_width">
              <div
                className="chart-feature-inner-flex"
                style={{ opacity: "0" }}
              >
                <p className="label-text-yellow-heading">Dimensions</p>
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <span
                    className="chart_value_text cc-dimensions"
                    key={vehicle._maeTrimId}
                  >
                    {formatToOneDecimal(vehicle.calculatedMDPFeatures.cargo) +
                      (vehicle.calculatedMDPFeatures.cargo ? " cu ft." : "")}
                  </span>
                ))}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <span
                    className="chart_value_text cc-dimensions"
                    key={vehicle._maeTrimId}
                  >
                    {vehicle.calculatedMDPFeatures.headRoomFirstRow +
                      (vehicle.calculatedMDPFeatures.headRoomFirstRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <span
                    className="chart_value_text cc-dimensions"
                    key={vehicle._maeTrimId}
                  >
                    {vehicle.calculatedMDPFeatures.headRoomSecondRow +
                      (vehicle.calculatedMDPFeatures.headRoomSecondRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <span
                    className="chart_value_text cc-dimensions"
                    key={vehicle._maeTrimId}
                  >
                    {vehicle.calculatedMDPFeatures.legRoomFirstRow +
                      (vehicle.calculatedMDPFeatures.legRoomFirstRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
              <div className="chart-feature-inner-flex">
                {carsToCompareList.map((vehicle) => (
                  <span
                    className="chart_value_text cc-dimensions"
                    key={vehicle._maeTrimId}
                  >
                    {vehicle.calculatedMDPFeatures.legRoomSecondRow +
                      (vehicle.calculatedMDPFeatures.legRoomSecondRow
                        ? " in"
                        : "")}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
