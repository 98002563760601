import { ENDPOINT_COMPARISON_CHART } from "../endpoints/url";
import requestHeader from "../Header";
export const trackAndUpdateComparisonChartInfo = async (requestObj) => {
  const response = await fetch(
    `${ENDPOINT_COMPARISON_CHART}/trackandupdatecomparisonchartinfo`,
    {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify({ infoLabels: requestObj }),
      credentials: "include",
    }
  );

  if (response.ok) {
    console.log(
      "Comparison Chart Info Tracked Successfully tracked successfully"
    );
  } else {
    const errorResponse = await response.json();
    console.error("Failed to track Comparison Chart Info", errorResponse.error);
  }
};

export const updateComparisonChartInfo = async (carList,extraVehicles=[]) => {
  const response = await fetch(
    `${ENDPOINT_COMPARISON_CHART}/updatecomparisonchartstate`,
    {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify({ maeTrimIdList: carList, extraVehicles }),
      credentials: "include",
    }
  );
  const res = await response.json();
  if (response.ok) {
    console.log("comparison chart state updated");
    console.log(res, "res at updation at comparison chart");
    return res;
  } else {
    console.error("Failed to update comparison chart", res.error);
  }
};
