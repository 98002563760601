import { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/formStyles/formStyles.scss";
import "./passwordResetForm.scss";
import error from "../../assets/images/error.svg";
import PassHidden from "../../assets/images/PassHidden.svg";
import PassVisible from "../../assets/images/PassVisible.svg";
import { ENDPOINT_ACCOUNT } from "../../endpoints/url";
import requestHeader from "../../Header";

const PasswordResetForm = ({ setResetPasswordForm }) => {
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmedPassword, setNewConfirmedPassword] = useState("");
  const [isFieldFocusedNew, setFieldFocusedNew] = useState(false);
  const [isFieldFocusedConfirmed, setFieldFocusedConfirmed] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const handleNewPasswordField = (focused) => {
    setFieldFocusedNew(focused);
  };

  const handleNewConfirmedPasswordField = (focused) => {
    setFieldFocusedConfirmed(focused);
  };

  const routes = useLocation();

  const newPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const confirmedPasswordVisibility = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const resetUserPassword = async (passwordInfo) => {
    const id_arr = routes.pathname.split("/");
    try {
      const response = await fetch(`${ENDPOINT_ACCOUNT}/forgot-password`, {
        method: "POST",
        headers: requestHeader,
        credentials: "include",
        body: JSON.stringify({
          userId: id_arr[2],
          token: id_arr[3],
          newPassword: passwordInfo.new,
          confirmedPassword: passwordInfo.confirm,
        }),
      });
      if (response.ok) {
        const res = await response.json();
        setResetPasswordForm(false);
      } else {
        const errorData = await response.json();
        console.log(errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  return (
    <>
      <div className="fp-wrapper__title">
        <h2 style={{ margin: 0 }}>Password Reset</h2>
      </div>
      <div className="fp-wrapper__description">
        <p style={{ margin: 0 }}>
          Please check your inbox to reset your password! Don’t worry, we’ll get
          you back to your matches in no time.
        </p>
      </div>
      <div
        className={`${
          newPassword !== newConfirmedPassword ? "red-border" : ""
        } fp-wrapper__inputfield`}
      >
        <div className="pass-input">
          <label className="input-label">Password</label>
          <input
            className="form-input"
            type={!showNewPassword ? "password" : "text"}
            autoComplete="off"
            onFocus={() => handleNewPasswordField(true)}
            onBlur={() => handleNewPasswordField(false)}
            name="new-password"
            value={newPassword}
            onChange={(ev) => {
              setNewPassword(ev.target.value);
            }}
          />
        </div>
        {!showNewPassword ? (
          <img src={PassHidden} alt="show" onClick={newPasswordVisibility} />
        ) : (
          <img src={PassVisible} alt="Hide" onClick={newPasswordVisibility} />
        )}
      </div>
      <div
        className={`${
          newPassword !== newConfirmedPassword ? "red-border" : ""
        } fp-wrapper__inputfield`}
      >
        <div className="pass-input">
          <label className="input-label">Confirm Password</label>
          <input
            className="form-input"
            type={!showConfirmedPassword ? "password" : "text"}
            autoComplete="off"
            onFocus={() => handleNewConfirmedPasswordField(true)}
            onBlur={() => handleNewConfirmedPasswordField(false)}
            name="confirmed-password"
            value={newConfirmedPassword}
            onChange={(ev) => {
              setNewConfirmedPassword(ev.target.value);
            }}
          />
        </div>
        {!showConfirmedPassword ? (
          <img
            src={PassHidden}
            onClick={confirmedPasswordVisibility}
            alt="show"
          />
        ) : (
          <img
            src={PassVisible}
            alt="Hide"
            onClick={confirmedPasswordVisibility}
          />
        )}
      </div>
      {newPassword !== newConfirmedPassword ? (
        <div style={{ display: "flex", gap: "4px" }}>
          <img src={error} alt="error" />
          <span className="password-error">
            Please make sure passwords match
          </span>
        </div>
      ) : null}
      <div className="fp-wrapper__reset-btn">
        <button
          className="sendemailbtn-text"
          onClick={() => {
            if (
              newPassword.length === 0 ||
              newPassword !== newConfirmedPassword
            )
              return;
            resetUserPassword({
              new: newPassword,
              confirm: newConfirmedPassword,
            });
            setNewPassword("");
            setNewConfirmedPassword("");
          }}
        >
          Reset Password
        </button>
      </div>
    </>
  );
};

export default PasswordResetForm;
